<template>
  <div class="readme-article">
    <h2 id="客户统计">4.客户统计</h2>
    <h3 id="客户数据">4.1客户数据</h3>
    <p>
      系统数据统计，包括客户统计和群统计的总数，每日新增流失情况；可通过时间、员工筛选获取客户量情况。
    </p>
    <p>
      <img src="@/assets/img/scrm/4-11.png" />
    </p>
    <h3 id="表格展示">4.2表格展示</h3>
    <p>表格形式展示筛选的客户数据。</p>
    <p>
      <img src="@/assets/img/scrm/4-10.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Scrm4-4",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>